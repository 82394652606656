import { Field, Form, Formik } from "formik";
import _ from "lodash";
import queryString from "query-string";
import React from "react";
import * as yup from "yup";
import TextFormikField from "../../components/FormikFields/TextFormikField";
import WaitingAnimation from "../../components/WaitingAnimation";
import { addError, addSuccess, useNotificationStore } from "../../utils/errors";
import { authenticationService } from "../../_services/authenticationService";

type PropsType = {
  location: { search: string };
};

export default ({ location }: PropsType) => {
  const InputSchema = yup.object().shape({
    email: yup.string().email("Invalid email").required("Bitte geben Sie Ihre Email an"),
  });

  const loc = queryString.parse(location.search);

  return (
    <div>
      Setzen Sie Ihr Password zurück, falls Sie es vergessen haben. Wir schicken Ihnen eine Email mit einem Bestätigungslink.
      <div style={{ maxWidth: "600px", margin: "auto", marginTop: "1em" }}>
        <Formik
          initialValues={{
            email: loc.user as string,
          }}
          validationSchema={InputSchema}
          onSubmit={({ email }, { setStatus, setSubmitting }) => {
            email = email.toLowerCase(); // Only lowercase is valid.
            setStatus();
            authenticationService
              .resetPassword(email)
              .then((res) => {
                setSubmitting(false);
                addSuccess("Wir haben Ihnen eine Email gesendet. Bitte prüfen Sie Ihren Posteingang.");
              })
              .catch((err) => {
                setSubmitting(false);
                addError(err.message);
              });
          }}
        >
          {({ isSubmitting, errors }) => (
            <Form>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <Field type="email" component={TextFormikField} className="w-full" name="email" />
              </div>
              <div className="form-group text-right">
                {isSubmitting ? <WaitingAnimation /> : null}
                <div>
                  <button className="button button-primary" type="submit" disabled={isSubmitting || _.some(errors)}>
                    Password zurücksetzen
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
